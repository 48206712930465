<script setup lang="ts">
import { getHomepageBestsellers } from "@/services/api/products";
import { onMounted, ref, watch } from "vue";
import type { ProductInterface } from "~/types/product";
import "vue3-carousel/dist/carousel.css";

const onboardingStore = useOnboardingStore();
const products: Ref<ProductInterface[]> = ref([]);
const skus = ["KID1048", "FFB7010", "KID1061", "FFB7005", "KID1069"];
const images: Record<string, string> = {
  KID1048:
    "https://images.dev.nurturelife.com/1674/conversions/Chicken-&-Mac-with-Hidden-Squash-&-Peas-meal-card-desktop.jpg",
  FFB7010:
    "https://images.nurturelife.com/1726/conversions/Spaghetti-Bites-with-Meatballs-&-Broccoli-(2)-meal-card-desktop.jpg",
  KID1061:
    "https://images.dev.nurturelife.com/1657/conversions/Chicken-Taco-Pockets-with-Fiesta-Rice-meal-card-desktop.jpg",
  FFB7005:
    "https://images.nurturelife.com/1716/conversions/Shepherd's-Pie-with-Rainbow-Veggie-Medley-(1)-meal-card-desktop.jpg",
  KID1069:
    "https://images.dev.nurturelife.com/1648/conversions/Swedish-Meatballs-with-Ribbons-&-Broccoli-meal-card-desktop.jpg",
};
const isLoading = ref(true);

const getBestSellers = () => {
  if (!onboardingStore.abandonedCartId) {
    return;
  }
  getHomepageBestsellers({
    onlySkus: skus,
    abandonedCartId: onboardingStore.abandonedCartId,
  })
    .then(({ data }) => {
      const orderIndex = Object.fromEntries(
        skus.map((sku, index) => [sku, index])
      );

      const tempProducts = data.data as ProductInterface[];

      // Sort the products array based on the order specified
      tempProducts.sort((a, b) => orderIndex[a.sku] - orderIndex[b.sku]);

      products.value = tempProducts.slice(0, 6);
    })
    .finally(() => {
      isLoading.value = false;
    });
};

onMounted(async () => {
  await getBestSellers();
});

watch(
  () => onboardingStore.abandonedCartExist,
  async () => {
    await getBestSellers();
  }
);

const addProduct = (sku: string) => {
  document.location.href = `/menus?navItem=heatable-meals&skus=${sku}`;
};
</script>
<template>
  <div
    v-if="!isLoading"
    class="slider-holder tw-relative slider-bestseller [&_li]:tw-px-1 md:[&_li]:tw-px-2.5 max-md:-tw-mx-2"
  >
    <Carousel
      :items-to-show="2"
      :wrap-around="true"
      :mouse-drag="true"
      :snap-align="'start'"
      :breakpoints="{
        768: {
          itemsToShow: 3,
          itemsToScroll: 1,
          snapAlign: 'center',
        },
        1024: {
          itemsToShow: 5,
          itemsToScroll: 1,
          snapAlign: 'center',
        },
      }"
    >
      <Slide v-for="product in products" :key="`bestsellers${product.sku}`">
        <div class="tw-h-full tw-w-full">
          <div
            class="tw-bg-nl-white-100 tw-rounded-xl tw-h-full tw-flex tw-flex-col tw-text-nl-green-100 tw-font-medium tw-text-sm tw-leading-[1.0715] md:tw-text-[15px] tw-leading-[1.014]"
          >
            <div
              class="tw-relative tw-rounded-t-xl tw-overflow-hidden tw-pt-[104%] sm:tw-pt-[100%] -tw-mx-px"
            >
              <img
                class="tw-object-cover tw-absolute tw-top-0 tw-left-0 tw-w-full"
                :src="images[product.sku]"
                :alt="product.name"
              />
            </div>
            <div
              class="tw-pt-1 md:tw-pt-1.5 tw-px-2 tw-pb-2 tw-flex-1 tw-flex tw-flex-col"
            >
              <div class="tw-flex-1">
                <div
                  class="tw-font-black tw-text-base md:tw-text-[18px] tw-leading-5 tw-mb-px md:tw-mb-0.5 tw-text-left"
                >
                  {{ product.name }}
                </div>
                <div class="tw-mb-2.5 tw-h-full tw-text-start">
                  {{ product.with }}
                </div>
              </div>
              <button
                class="btn-kaitoke-green"
                type="button"
                @click="() => addProduct(product.sku)"
              >
                Add to Cart
              </button>
            </div>
          </div>
        </div>
      </Slide>

      <template #addons>
        <Navigation />
      </template>
    </Carousel>
  </div>
</template>

<style>
.slider-bestseller .carousel__prev {
  left: -27px;
}

.slider-bestseller .carousel__next {
  right: -27px;
}
</style>
