<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import { getLeastExpensiveProduct } from "@/services/api/products";
import { restoreAbandonedCartId } from "@/services/storage";
import { findGetParameter } from "~/utils/helpers";

interface Product {
  discount: number;
  discount_type: string;
  price: {
    data: {
      display_price: number;
      original_display_price: number;
      display_price_in_cents: number;
      original_display_price_in_cents: number;
    };
  };
}

const product = ref<Product | null>(null);
const busy = ref(true);
const cartStore = useCartStore();
const experimentStore = useExperimentStore();
const { promoCode, referralCode } = storeToRefs(cartStore);
const { isExperimentsLoaded } = storeToRefs(experimentStore);

const format = (money: number) => formatMoney(money);

const getProduct = async () => {
  if (!isExperimentsLoaded.value) {
    return;
  }
  busy.value = true;
  try {
    const tempPromo = findGetParameter("pc");
    product.value = await getLeastExpensiveProduct({
      promoCode: tempPromo || (promoCode && promoCode.value && promoCode.value.code ? promoCode.value.code : null),
      abandonedCartId: restoreAbandonedCartId(),
    });
  } catch (exception: any) {
    const is403 = exception.response && exception.response.status === 403;
    if (!is403) {
      throw exception;
    }
  } finally {
    busy.value = false;
  }
};

if (promoCode) {
  watch(promoCode, () => {
    getProduct();
  });
}

if (referralCode) {
  watch(referralCode, () => {
    getProduct();
  });
}

watch(isExperimentsLoaded, () => {
  getProduct();
});

const showPrice = computed(() => price.value >= 0 && isExperimentsLoaded.value);
const price = computed(() => product.value?.price?.data?.display_price ?? 0);

const priceBeforeDiscount = computed(
  () => product.value?.price?.data?.original_display_price ?? 0
);

const percentage = computed(() => {
  const before = product.value?.price?.data?.original_display_price_in_cents;
  const after = product.value?.price?.data?.display_price_in_cents;

  if (before && before > 0 && after && after > 0) {
    return Math.abs(parseInt(((after - before) / before * 100).toString(), 10));
  }
  return 0;
});

onMounted(async () => {
  await getProduct();
});
</script>

<template>
  <div v-if="showPrice">
    <span
      class="tw-block tw-font-bold tw-text-[14px] md:tw-text-[16px] tw-leading-[1.21] tw-text-nl-green-500 md:tw-text-nl-yellow-100"
    >
      Starting at {{ format(price) }}/item
    </span>
    <span
      v-if="product && product?.discount > 0 && product?.discount_type !== 'amount'"
      class="tw-block tw-text-[14px] md:tw-text-[16px] tw-leading-[1.21] tw-font-medium"
    >
      was
      <span
        class="tw-relative after:tw-content-[''] after:tw-absolute after:tw-top-1/2 after:tw-transform after:-tw-translate-y-1/2 after:-tw-left-0.5 after:-tw-right-0.5 after:tw-h-0.5 after:tw-rounded-[2px] after:tw-bg-nl-green-100 after:md:tw-bg-nl-yellow-100"
      >
        {{ format(priceBeforeDiscount) }}
      </span>
      <span v-if="percentage">({{ percentage }}% off)</span>
    </span>
  </div>
</template>
